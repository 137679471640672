import Color from './Color'

type Props<C> = {
  colors: C[]
  color: C
  onSelected: (color: C) => void
}

export function ToneSlider<C extends { id: string; hex: string }>({
  colors,
  color,
  onSelected,
}: Readonly<Props<C>>) {
  const colorsWithPercentages = colors.map((color, index) => ({
    color,
    percentage: (index / (colors.length - 1)) * 100,
  }))

  const gradientStops = colorsWithPercentages
    .map(
      ({ color: { hex }, percentage }) =>
        `color-mix(in srgb, ${hex}, black 10%) ${percentage}%`,
    )
    .join(', ')

  const selectedPercentage = colorsWithPercentages.find(
    (x) => x.color.id === color.id,
  )?.percentage

  return (
    <div
      style={{ background: `linear-gradient(to right, ${gradientStops})` }}
      className="relative mx-[36px] my-[15px] h-[2px]"
    >
      {colorsWithPercentages.map(({ color, percentage }) => (
        // Why the nesting? As to provide a bigger hitbox
        <div
          key={color.id}
          onClick={() => onSelected(color)}
          style={{ left: `calc(${percentage}% - 16px)` }}
          className="absolute h-[32px] w-[32px] translate-y-[-15px] cursor-pointer rounded-full p-[8px]"
        >
          <div
            style={{ backgroundColor: color.hex }}
            className="h-[16px] w-[16px] rounded-full outline outline-[2px] -outline-offset-[2px] outline-black/10"
          />
        </div>
      ))}
      <div
        style={{ left: `calc(${selectedPercentage}% - 16px)` }}
        className="absolute translate-y-[-15px]"
      >
        <Color color={color.hex} />
      </div>
    </div>
  )
}

export default ToneSlider
