// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { Text } from './nodes'
import { AnyNodeOpts } from './types'

type TextOpts = AnyNodeOpts & {
  maxLength?: number
  pattern?: RegExp
  inputType?: 'text' | 'tel'
}

const _Text: (opts: TextOpts) => any = Text

export default _Text
