import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { BjsRenderer, RendererConfig } from '@orangelv/bjs-renderer'

import { getRendererConfig } from '../bjs-renderer-utils'
import Spinner from '../../../../platform/spectacle-base/client/components/Spinner'
import { Recipe, ViewerRecipe } from '../../common/types'
import { AppContext, LoadingContext, RosterContext } from '../create-app'
import { generateIconsForProducts } from '../generate-icons'
import { BREAKPOINT } from '../../../../../tailwind.config'
import { useMediaQuery } from '../../../client/hooks'
import { ControlTree } from '../../../client/control-tree/types'
import fullCustomOffsets from '../../assets/full-custom-templates/offsets.json'

const Viewer = ({
  autoRotate,
  skybox,
}: {
  autoRotate: boolean
  skybox: undefined | string
}) => {
  const {
    colorDict,
    fillDict,
    controlTree: ct,
    customFonts,
    designParts,
    designs,
    fonts,
    patternPackage,
    placements,
    products,
    vendor,
  } = useContext(AppContext)
  const controlTree = ct as ControlTree
  const { playerBeingPreviewed } = useContext(RosterContext)
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const recipe: Recipe = useSelector(controlTree.getPublicRecipe)
  const patternName = recipe['sku'] as keyof typeof fullCustomOffsets
  const hasFullUI = useMediaQuery(`(min-width: ${BREAKPOINT}px)`)

  // https://react.dev/reference/react/useEffect#fetching-data-with-effects
  const [config, setConfig] = useState<RendererConfig>()
  useEffect(() => {
    const viewerRecipe: ViewerRecipe = { ...recipe }

    viewerRecipe['playerName.text'] =
      viewerRecipe[`players.roster.${playerBeingPreviewed}.name`]
    viewerRecipe['playerName.font'] = recipe['players.nameStyle.font']
    viewerRecipe['playerName.color'] = recipe['players.nameStyle.color']
    viewerRecipe['playerName.outline1Color'] =
      recipe['players.nameStyle.outline1Color']
    viewerRecipe['playerName.outline2Color'] =
      recipe['players.nameStyle.outline2Color']

    viewerRecipe['playerNumber.text'] =
      recipe[`players.roster.${playerBeingPreviewed}.number`]
    viewerRecipe['playerNumber.font'] = recipe['players.numberStyle.font']
    viewerRecipe['playerNumber.color'] = recipe['players.numberStyle.color']
    viewerRecipe['playerNumber.outline1Color'] =
      recipe['players.numberStyle.outline1Color']
    viewerRecipe['playerNumber.outline2Color'] =
      recipe['players.numberStyle.outline2Color']

    let ignore = false
    ;(async () => {
      const result = await getRendererConfig({
        autoRotate,
        colorDict,
        fillDict,
        customFonts,
        designParts,
        fonts,
        patternName,
        patternPackage,
        placements,
        playerBeingPreviewed,
        recipe: viewerRecipe,
        skybox,
        vendor,
        hasFullUI,
      })
      if (!ignore) setConfig(result)
    })()
    return () => {
      ignore = true
    }
  }, [
    autoRotate,
    colorDict,
    fillDict,
    customFonts,
    designParts,
    fonts,
    patternName,
    patternPackage,
    placements,
    playerBeingPreviewed,
    recipe,
    skybox,
    vendor,
    hasFullUI,
  ])

  return (
    <>
      {config && (
        <div className="fixed h-screen w-screen">
          <BjsRenderer
            config={config}
            onLoading={(x) => {
              setIsLoading(x)
            }}
            onGeneratePreviews={(generatePreviews) => {
              ;(window as any).generateIconsForProducts = () =>
                generateIconsForProducts(generatePreviews, products, designs, {
                  colorDict,
                  fillDict,
                  customFonts,
                  designParts,
                  fonts,
                  patternPackage,
                  placements,
                  playerBeingPreviewed,
                  recipe,
                  vendor,
                })
            }}
          />
        </div>
      )}
      {isLoading && (
        <div className="fixed left-1/2 top-1/2 -ml-8 md:left-modelCenter">
          <Spinner />
        </div>
      )}
    </>
  )
}

export default Viewer
