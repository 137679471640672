import type { GeneratePreviews } from '@orangelv/bjs-renderer'
import { Immutable } from '@orangelv/utils'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import type { ProductRow, DesignRow, ViewerRecipe } from '../common/types'
import { GetRendererConfigArgs, getRendererConfig } from './bjs-renderer-utils'
import assert from '../../assert'
import fullCustomOffsets from '../assets/full-custom-templates/offsets.json'

const PRODUCT_ICON_SCALING = 1.3

export async function generateIconsForProducts(
  generatePreviews: GeneratePreviews,
  products: Immutable<ProductRow[]>,
  designs: Immutable<DesignRow[]>,
  configArgs: Omit<
    GetRendererConfigArgs,
    'autoRotate' | 'skybox' | 'patternName'
  >,
) {
  const zip = new JSZip()

  const designsWithNone = [{ id: null }, ...designs]

  for (const product of products) {
    if (!product.isEnabled) {
      continue
    }
    for (const design of designsWithNone) {
      if (
        !(design.id === null) &&
        !design.limitations.productIds.includes(product.id)
      ) {
        continue
      }

      const { recipe } = configArgs
      const viewerRecipe: ViewerRecipe = { ...recipe }

      viewerRecipe['playerName.text'] = viewerRecipe['players.roster.1.name']
      viewerRecipe['playerName.font'] = recipe['players.nameStyle.font']
      viewerRecipe['playerName.color'] = recipe['players.nameStyle.color']
      viewerRecipe['playerName.outline1Color'] =
        recipe['players.nameStyle.outline1Color']
      viewerRecipe['playerName.outline2Color'] =
        recipe['players.nameStyle.outline2Color']

      viewerRecipe['playerNumber.text'] = recipe['players.roster.1.number']
      viewerRecipe['playerNumber.font'] = recipe['players.numberStyle.font']
      viewerRecipe['playerNumber.color'] = recipe['players.numberStyle.color']
      viewerRecipe['playerNumber.outline1Color'] =
        recipe['players.numberStyle.outline1Color']
      viewerRecipe['playerNumber.outline2Color'] =
        recipe['players.numberStyle.outline2Color']

      viewerRecipe['design.design'] = design.id

      if (design.id === 'fullCustom') {
        viewerRecipe['design.file'] = { id: '-', filename: '-' }
        viewerRecipe['players.roster.1.name'] = ''
        viewerRecipe['players.roster.1.number'] = ''
        viewerRecipe['teamName.text'] = ''
      }

      viewerRecipe['sku'] = product.id

      const rendererConfig = await getRendererConfig({
        ...configArgs,
        playerBeingPreviewed: 1,
        recipe: viewerRecipe,
        patternName: product.id as keyof typeof fullCustomOffsets,
        autoRotate: false,
        skybox: undefined,
      })

      assert(rendererConfig.models.main, 'Model "main" not found.')
      const meshes = rendererConfig.models.main.meshes
      const root = meshes?.__root__

      rendererConfig.models.main.meshes = {
        ...meshes,
        __root__: {
          ...root,
          scaling: {
            x: PRODUCT_ICON_SCALING,
            y: PRODUCT_ICON_SCALING,
            z: PRODUCT_ICON_SCALING,
          },
        },
      }

      const blob = (
        await generatePreviews({
          rendererConfig,
          size: { width: 512, height: 640 },
        })
      ).default

      zip.file(`${product.id}-${design.id ?? 'none'}.png`, blob)
    }
  }

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'icons-for-products.zip')
}
