/* eslint-disable @eslint-react/no-missing-key -- paths don't need a key in this case */
import {
  type AllOrNothing,
  hasLengthAtLeast,
  type PartialWithUndefined,
  type Tuple,
} from '@orangelv/utils';
import { add2, in2px, type Vector2 } from '@orangelv/utils-geometry';
import { type SvgData, svgTranslate } from '@orangelv/utils-svg';
import {
  bluntJoints,
  getPathDataControlPointBoundingBox,
  getPathDataGroupBoundingBox,
  type MinimalCommand,
  type MiterLimitOverride,
  openTypePathToSvgPathData,
  stringifySvgPathData,
} from '@orangelv/utils-svg-path-data';
import type { Font } from 'opentype.js';

import { makeLayout, type TextLayout } from './text-layouts.js';
import { createTail } from './text-tails.js';
import type { BoxSizing } from './types.js';

const OUTLINE_THICKNESS_STEP = in2px(1 / 8);
const OUTLINE_THICKNESS_1 = OUTLINE_THICKNESS_STEP;
const OUTLINE_THICKNESS_2 = OUTLINE_THICKNESS_STEP * 2;

// Doesn't matter much, we blunt sharp joints. Keeping relatively low as to
// catch potential edge cases.
const STROKE_MITER_LIMIT = 8;

// This value is a guess, adjust if needed. 2.5 appears to be pretty close to
// an optimal compromise between having pointy features while not letting some
// of them point their way out of the universe.
const OUTLINE_BLUNTING_MITER_LIMIT = 2.5;

// Same as above but for glyph tails. This is a little over a square root of 2,
// permitting square miters, but not more.
const OUTLINE_BLUNTING_MITER_LIMIT_TAIL = 1.42;

// In pixels, doesn't really need to be smaller than this
const OUTLINE_BLUNTING_DISTANCE = 0.1;

// https://en.wikipedia.org/wiki/Cap_height
//
// Some fonts also have `sCapHeight` in the `os2` table, but not all, and
// sometimes it has nothing to do with the reality. Better just calculate
// ourselves. The undershoot is useful for finding the real baseline, which is
// supposed to be 0, but sometimes isn't.
//
// Traditionally cap/x height appears to be straight up `yMax`, but
// historically we chose to go for `yMax - yMin`. The difference is minimal,
// and it currently isn't worth the change, but it could be in the future.
function getVerticalMetrics(
  font: Font,
  character: string,
): {
  yMin: number;
  yMax: number;
  height: number;
  undershoot: number;
} {
  const { yMin, yMax } = font.charToGlyph(character).getMetrics();
  return { yMin, yMax, height: yMax - yMin, undershoot: -yMin };
}

export const getCapMetrics = (
  font: Font,
): ReturnType<typeof getVerticalMetrics> => getVerticalMetrics(font, 'H');

const getXMetrics = (font: Font): ReturnType<typeof getVerticalMetrics> =>
  getVerticalMetrics(font, 'x');

// os2.sTypoLineGap is usually ~1.2 em, but that's good for lines of text,
// rather than vertical text. 1 em looks good enough as a default.
//
// https://learn.microsoft.com/en-us/typography/opentype/spec/recom
const EM_TO_LINE_GAP = 1;

export function renderText(
  font: Font,
  textRaw: string,
  {
    size = 72,
    fill = '#000000',
    layout,
    tail,
    outlineColor1,
    outlineColor2,
    letterSpacing = 0,
    boxSizing = 'border-box',
    isVertical,
  }: PartialWithUndefined<{
    size: number;
    fill: string | SvgData;
    layout: TextLayout;
    tail: { svg: SvgData } & PartialWithUndefined<{ isConnected: boolean }> &
      AllOrNothing<{
        text: string;
        textFont: Font;
        textColor: string;
      }>;
    outlineColor1: string;
    outlineColor2: string;
    letterSpacing: number;
    boxSizing: BoxSizing; // See CSS box-sizing, similar
    isVertical: boolean;
  }> = {},
): SvgData {
  const text = textRaw.trim();
  // eslint-disable-next-line @eslint-react/no-useless-fragment -- The root is needed in this case
  if (text === '') return { name: '', width: 0, height: 0, root: <></> };

  // All metrics inside fonts are expressed as if `size = unitsPerEm`.
  const fontScale = size / font.unitsPerEm;

  const paths =
    isVertical ?
      text.split('').map((char, index) => {
        const glyph = font.charToGlyph(char);
        const box = glyph.getBoundingBox();
        return glyph.getPath(
          // -x1 aligns glyphs with x=0, and the rest centers them in em
          (-box.x1 + (font.unitsPerEm - (box.x2 - box.x1)) / 2) * fontScale,
          index * size * (EM_TO_LINE_GAP + letterSpacing),
          size,
        );
      })
    : font.getPaths(text, 0, 0, size, { letterSpacing });

  let pathDataGroup = paths.map((x) => openTypePathToSvgPathData(x));
  let boundingBox = getPathDataGroupBoundingBox(pathDataGroup);

  // Stable approximation of the height of the text. Used as to avoid layouts
  // and tails being affected by the contents of the text.
  const cap = getCapMetrics(font);
  const lower = getXMetrics(font);

  // https://en.wikipedia.org/wiki/Baseline_(typography)
  //
  // This isn't precise horizontally (need to take bearing and advance into
  // account too), but accurate enough.
  const baseline: Tuple<Vector2, 2> = [
    { x: boundingBox.left, y: 0 },
    { x: boundingBox.right, y: 0 },
  ];

  const totalOutlineThickness =
    outlineColor2 === undefined ?
      outlineColor1 === undefined ?
        0
      : OUTLINE_THICKNESS_1
    : OUTLINE_THICKNESS_2;

  // Why is this done before laying out the text? Because once the text is laid
  // out, that's it, we don't know where the tails went, because we don't know
  // the exact rotation, translation or warping. Why not bake the outline paths
  // here then, and lay them out along with the fill paths? Because we care
  // about the final miter angle, not pre-layout, and it could differ a lot.

  let glyphTailSearchPolygonGroup: MinimalCommand[][] | undefined;
  if (totalOutlineThickness > 0) {
    glyphTailSearchPolygonGroup = pathDataGroup.map((pathData, index) => {
      const character = text[index];
      if (character === undefined) throw new Error('Logic error');

      const glyph = font.charToGlyph(character);
      if (
        glyph.advanceWidth === undefined ||
        glyph.leftSideBearing === undefined
      ) {
        throw new Error('Bad glyph');
      }

      const glyphBox = getPathDataControlPointBoundingBox(pathData);
      const bearing = glyph.leftSideBearing * fontScale;
      const advance = glyph.advanceWidth * fontScale;

      // What's happening here? We're targeting two rectangular areas in each
      // glyph, looking for corners that may end up poking through the outline
      // of adjacent characters and making the text look bad. We later ask
      // `bluntJoints` to apply a miter limit of 1 to them.

      // Why is this defined as SVG path data, rather than just polygons?
      // Because `makeLayout` deals with vector text, and it would be a
      // non-trivial amount of code to make it work with plain polygons.

      // 20% to 80% of x-height above baseline
      const top = -lower.height * fontScale * 0.7;
      const bottom = -lower.height * fontScale * 0.3;

      // Up to 10% of glyph width from the left
      const aLeft = glyphBox.left - 1;
      const aRight = glyphBox.left - bearing + 1;

      // Up to next glyph's origin from the right
      const bLeft = glyphBox.left + advance;
      const bRight = glyphBox.right + 1;

      return [
        { type: 'M', values: [aLeft, top] },
        { type: 'M', values: [aRight, top] },
        { type: 'M', values: [aRight, bottom] },
        { type: 'M', values: [aLeft, bottom] },
        { type: 'M', values: [bLeft, top] },
        { type: 'M', values: [bRight, top] },
        { type: 'M', values: [bRight, bottom] },
        { type: 'M', values: [bLeft, bottom] },
      ];
    });
  }

  if (layout !== undefined) {
    if (isVertical) throw new Error('Layouts are horizontal-text-oriented');

    const ascenderHeight = cap.height * fontScale;
    const [pathDataGroupAlias, baselineDelta] = makeLayout(
      layout,
      pathDataGroup,
      boundingBox.width,
      ascenderHeight,
    );

    pathDataGroup = pathDataGroupAlias;
    boundingBox = getPathDataGroupBoundingBox(pathDataGroup);

    // Layouts shift the glyphs around, and since tails "hug" the baseline, we'd
    // like to keep track of these shifts. We could warp the tails to match the
    // new baseline perfectly, but it's an overkill, an approximation of a
    // baseline stretching from the bottom left of the first letter to the
    // bottom right of the last letter should suffice. "makeLayout" returns the
    // delta for both edges, that we apply to the original baseline.
    baseline[0] = add2(baseline[0], baselineDelta[0]);
    baseline[1] = add2(baseline[1], baselineDelta[1]);

    if (glyphTailSearchPolygonGroup) {
      [glyphTailSearchPolygonGroup] = makeLayout(
        layout,
        glyphTailSearchPolygonGroup,
        boundingBox.width,
        ascenderHeight,
      );
    }
  }

  let tailText;
  if (tail) {
    if (isVertical) throw new Error("Tails don't make sense in vertical text");

    const lastCharacter = text.at(-1);
    const lastPathData = pathDataGroup.at(-1);
    if (lastCharacter === undefined || lastPathData === undefined) {
      throw new Error('Logic error');
    }

    // Font `post` table has `underlinePosition`, which could be used for the
    // margin instead of a fraction of `cap.height`, but it's wildly
    // inconsistent, sometimes even equal to 0.
    const [tailPathData, tailTextAlias] = createTail(
      tail,
      baseline,
      (cap.undershoot + 0.05 * cap.height) * fontScale +
        totalOutlineThickness * 2,
      0.5 * cap.height * fontScale,
      font.charToGlyph(lastCharacter),
      lastPathData,
      fontScale,
    );

    pathDataGroup.push(tailPathData);
    boundingBox = getPathDataGroupBoundingBox(pathDataGroup);
    tailText = tailTextAlias;
  }

  const pathDataStrings = pathDataGroup.map((x) => stringifySvgPathData(x));
  const fillValue = typeof fill === 'string' ? fill : `url(#${fill.name})`;
  const boundingBoxOutlineThickness =
    boxSizing === 'content-box' ? 0 : totalOutlineThickness;

  let outline2;
  let outline1;
  if (outlineColor2 !== undefined || outlineColor1 !== undefined) {
    const outlinePaths = pathDataGroup.map((pathData, index) => {
      let miterLimitOverrides: MiterLimitOverride[] | undefined;
      const glyphTailSearchPolygons = glyphTailSearchPolygonGroup?.[index];
      if (glyphTailSearchPolygons) {
        const vertices = glyphTailSearchPolygons.map((command) => {
          if (command.type !== 'M') throw new Error('Logic error');
          return { x: command.values[0], y: command.values[1] };
        });

        if (!hasLengthAtLeast(vertices, 8)) throw new Error('Logic error');

        miterLimitOverrides = [
          {
            polygon: [vertices[0], vertices[1], vertices[2], vertices[3]],
            value: OUTLINE_BLUNTING_MITER_LIMIT_TAIL,
          },
          {
            polygon: [vertices[4], vertices[5], vertices[6], vertices[7]],
            value: OUTLINE_BLUNTING_MITER_LIMIT_TAIL,
          },
        ];
      }

      return stringifySvgPathData(
        bluntJoints(
          pathData,
          OUTLINE_BLUNTING_MITER_LIMIT,
          totalOutlineThickness,
          OUTLINE_BLUNTING_DISTANCE,
          1,
          miterLimitOverrides,
        ),
      );
    });

    if (outlineColor2 !== undefined) {
      outline2 = (
        <>
          {outlinePaths.map((d) => (
            <path
              d={d}
              fill="none"
              stroke={outlineColor2}
              strokeWidth={OUTLINE_THICKNESS_2 * 2}
              strokeMiterlimit={STROKE_MITER_LIMIT}
            />
          ))}
        </>
      );
    }

    if (outlineColor1 !== undefined) {
      outline1 = (
        <>
          {outlinePaths.map((d) => (
            <path
              d={d}
              fill="none"
              stroke={outlineColor1}
              strokeWidth={OUTLINE_THICKNESS_1 * 2}
              strokeMiterlimit={STROKE_MITER_LIMIT}
            />
          ))}
        </>
      );
    }
  }

  // Why render each pathData separately, instead of concatenating
  // "pathDataStrings" into a single string? Because SVG renders paths in
  // "exclude" mode when they're wound in opposing directions (i.e. clockwise
  // and anticlockwise), as demonstrated on MDN [0]. But we want unconditional
  // "union". This can be seen when using the Noble font, it's anticlockwise.
  //
  // [0] https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/fill-rule

  const root = (
    <>
      {typeof fill === 'string' ?
        undefined
      : <defs>
          <pattern
            id={fill.name}
            patternUnits="userSpaceOnUse"
            width={fill.width}
            height={fill.height}
          >
            {fill.root}
          </pattern>
        </defs>
      }
      <g
        transform={svgTranslate(
          -boundingBox.x + boundingBoxOutlineThickness,
          -boundingBox.y + boundingBoxOutlineThickness,
        )}
      >
        {outline2}
        {outline1}
        {pathDataStrings.map((d) => (
          <path d={d} fill={fillValue} />
        ))}
        {tailText}
      </g>
    </>
  );

  return {
    name: '',
    width: boundingBox.width + boundingBoxOutlineThickness * 2,
    height: boundingBox.height + boundingBoxOutlineThickness * 2,
    root,
  };
}

export { Font, load as loadFont, parse as parseFont } from 'opentype.js';
