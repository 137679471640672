import { EyeIcon as EyeIconOutline } from '@heroicons/react/24/outline'
import { EyeIcon as EyeIconSolid } from '@heroicons/react/24/solid'
import { ReactNode, useContext } from 'react'
import { useSelector } from 'react-redux'
// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { change } from '../../../../platform/client/control-tree'
import { AppDispatch, useAppDispatch } from '../../../client/configureStore'
import Icon from './Icon'
import { AppContext, RosterContext } from '../create-app'
import TileSet from './TileSet'
import RosterUpload from './RosterUpload'
import { updateChanges } from '../../../client/common/actions'
import {
  ControlTree,
  ControlTreeNode,
} from '../../../client/control-tree/types'
import { UnknownAction } from 'redux'

const RosterInput = ({
  onChange,
  value,
}: {
  onChange: (value: string) => void
  value: string
}) => (
  <input
    type="text"
    onChange={(e) => onChange(e.target.value)}
    value={value}
    className="block w-full rounded-lg rounded-none bg-white p-2 font-normal text-black focus:outline-none"
  />
)

const HeaderCell = ({ children }: { children?: ReactNode }) => (
  <th className="p-2">{children}</th>
)

const Cell = ({ children }: { children?: ReactNode }) => (
  <td className={'border-b border-b-lightGrey p-1'}>{children}</td>
)

const Row = ({ children }: { children: ReactNode }) => (
  <tr className="">{children}</tr>
)

const SizeSelect = ({
  value,
  onChange,
  options,
}: {
  value: string
  onChange: (value: string) => void
  options: { id: string; name: string }[]
}) => (
  <select
    value={value}
    onChange={(e) => onChange(e.target.value)}
    className="bg-white"
  >
    {options.map((option) => (
      <option value={option.id} key={option.id}>
        {option.name}
      </option>
    ))}
  </select>
)

function getNextNumber(prevNumber?: string) {
  let n = 1
  if (prevNumber) {
    const prevN = parseInt(prevNumber, 10)
    if (!Number.isNaN(prevN)) {
      n = prevN + 1
    }
  }
  return `${n}`
}

const dispatchToControlTree =
  (
    roster: {
      name: string | { id: string } | { name: string } | null | undefined
      number: string | { id: string } | { name: string } | null | undefined
      size: ControlTreeNode
    }[],
    controlTreeDispatch: AppDispatch,
    controlTree: ControlTree,
  ) =>
  (action: UnknownAction) => {
    switch (action.type) {
      case 'ADD_ROW': {
        const length = roster.length
        const number = getNextNumber(
          (roster[length - 1] as { number: string } | undefined)?.number,
        )
        controlTreeDispatch(controlTree.addNode('players.roster'))
        controlTreeDispatch(change(`players.roster.${length + 1}.name`, ''))
        controlTreeDispatch(
          change(`players.roster.${length + 1}.number`, number),
        )
        controlTreeDispatch(
          change(
            `players.roster.${length + 1}.size`,
            roster[0]?.size.defaultValue,
          ),
        )
        break
      }
      case 'REMOVE_ROW': {
        if (roster.length === 1) {
          controlTreeDispatch(controlTree.removeNode('players.roster', 1))
          controlTreeDispatch(controlTree.addNode('players.roster'))
          controlTreeDispatch(change('players.roster.1.name', ''))
          controlTreeDispatch(change('players.roster.1.number', '1'))
          controlTreeDispatch(
            change('players.roster.1.size', roster[0]?.size.defaultValue),
          )
          break
        }
        const { index } = action.payload as { index: number }
        controlTreeDispatch(controlTree.removeNode('players.roster', index))
        break
      }
      case 'SET_NAME': {
        const { index, name } = action.payload as {
          index: number
          name: string
        }
        controlTreeDispatch(change(`players.roster.${index + 1}.name`, name))
        break
      }
      case 'SET_NUMBER': {
        const { index, number } = action.payload as {
          index: number
          number: string
        }
        controlTreeDispatch(
          change(`players.roster.${index + 1}.number`, number),
        )
        break
      }
      case 'SET_SIZE': {
        const { index, size } = action.payload as {
          index: number
          size: string
        }
        controlTreeDispatch(change(`players.roster.${index + 1}.size`, size))
        break
      }
      default:
        throw new Error(`Unknown roster action: ${action.type}`)
    }
    return roster
  }

const getRoster =
  (controlTree: ControlTree) => (state: Record<string, unknown>) => {
    const nodes = controlTree.getNodes(state)
    const playerIds = controlTree.getRepeatedNodes(state, 'players.roster')
    const roster = playerIds.map((id) => {
      const name = nodes[`players.roster.${id}.name`].value
      const number = nodes[`players.roster.${id}.number`]?.value
      const size = nodes[`players.roster.${id}.size`]
      return { name, number, size }
    })
    return roster
  }

const RosterTable = () => {
  const {
    controlTree: ct,
    vendor: { features },
  } = useContext(AppContext)
  const controlTree = ct as ControlTree
  const { playerBeingPreviewed, setPlayerBeingPreviewed } =
    useContext(RosterContext)
  const roster = useSelector(getRoster(controlTree))
  const controlTreeDispatch = useAppDispatch()
  const dispatch = dispatchToControlTree(
    roster,
    controlTreeDispatch,
    controlTree,
  )

  const hasNumber = features.playerNumber
  const hasSize = roster[0]?.size.visibleOptions!.length > 1

  // We don't have anything better than the index as key since there's no row
  // id in the control tree for roster.
  /* eslint-disable @eslint-react/no-array-index-key */
  return (
    <TileSet>
      <div className="w-full bg-white p-5 pb-0">
        <table className="w-full border-separate border-spacing-2 text-left">
          <thead>
            <tr className="">
              <HeaderCell></HeaderCell>
              <HeaderCell>Name</HeaderCell>
              {hasNumber ?
                <HeaderCell>Number</HeaderCell>
              : null}
              {hasSize ?
                <HeaderCell>Size</HeaderCell>
              : null}
              <HeaderCell></HeaderCell>
            </tr>
          </thead>
          <tbody>
            {roster.map((player, index) => (
              <Row key={index}>
                <td className="border-b-none pt-2">
                  {playerBeingPreviewed === index + 1 ?
                    <EyeIconSolid
                      title="Player is being previewed"
                      className="h-6 w-6"
                    />
                  : <EyeIconOutline
                      title="Preview this player"
                      onClick={() => setPlayerBeingPreviewed(index + 1)}
                      className="h-6 w-6 cursor-pointer text-gray"
                    />
                  }
                </td>
                <Cell>
                  <RosterInput
                    onChange={(name) =>
                      dispatch({
                        type: 'SET_NAME',
                        payload: { name, index },
                      })
                    }
                    value={player.name as string}
                  />
                </Cell>
                {hasNumber ?
                  <Cell>
                    <RosterInput
                      onChange={(number) =>
                        dispatch({
                          type: 'SET_NUMBER',
                          payload: { number, index },
                        })
                      }
                      value={player.number as string}
                    />
                  </Cell>
                : null}
                {hasSize ?
                  <Cell>
                    <SizeSelect
                      options={player.size.visibleOptions!}
                      onChange={(size) =>
                        dispatch({
                          type: 'SET_SIZE',
                          payload: { size, index },
                        })
                      }
                      value={
                        (player.size.value as string | undefined) ??
                        player.size.defaultValue!
                      }
                    />
                  </Cell>
                : null}
                <td className="border-b-none pl-3 pt-4 text-gray">
                  <button
                    onClick={() => {
                      dispatch({
                        type: 'REMOVE_ROW',
                        payload: { index: index + 1 },
                      })
                      setPlayerBeingPreviewed((n) => {
                        // if we are removing the row being previewed set the previewed row to 1
                        if (index + 1 === n) {
                          return 1
                        }
                        // if we are removing a row before this row, decrement the row being previewed
                        if (index + 1 < n) {
                          return n - 1
                        }
                        return n
                      })
                    }}
                  >
                    <Icon name="close" />
                  </button>
                </td>
              </Row>
            ))}
            <Row>
              <td colSpan={3 + (hasNumber ? 1 : 0) + (hasSize ? 1 : 0)}>
                <button
                  onClick={() => {
                    dispatch({ type: 'ADD_ROW' })
                    setPlayerBeingPreviewed(roster.length + 1)
                  }}
                  className="w-full rounded-sm p-2 text-gray"
                >
                  <Icon name="plusBtn" />
                </button>
              </td>
            </Row>
          </tbody>
        </table>
        <div></div>
      </div>
      <RosterUpload
        setRosterValues={(data) => {
          const rows = data.slice(1)

          if (rows.length === 0) return

          for (const [rowIndex, data] of rows.entries()) {
            const index = rowIndex + 1
            const [size, number, name] = data

            if (index > roster.length) {
              controlTreeDispatch(controlTree.addNode('players.roster'))
            }

            controlTreeDispatch(change(`players.roster.${index}.name`, name))
            controlTreeDispatch(
              change(`players.roster.${index}.number`, number),
            )
            controlTreeDispatch(change(`players.roster.${index}.size`, size))
          }

          for (let i = roster.length; i > rows.length; i -= 1) {
            controlTreeDispatch(controlTree.removeNode('players.roster', i))
          }

          controlTreeDispatch(updateChanges(controlTree))
          setPlayerBeingPreviewed(1)
        }}
      />
    </TileSet>
  )
  /* eslint-enable @eslint-react/no-array-index-key */
}

export default RosterTable
