import {
  TextField as BaseTextField,
  Label,
  Input,
  FieldError,
} from 'react-aria-components'

type Props = {
  name: string
  label: string
  isRequired?: boolean
  type?: string
  className?: string
  onChange?: (value: string) => void
}

const TextField = (props: Props) => (
  <BaseTextField className="flex flex-col" {...props}>
    <Label className="mb-1 text-sm font-medium">
      {props.label}
      {props.isRequired && <span className="text-error">*</span>}
    </Label>
    <Input className="rounded-md border border-solid border-gray px-3 py-[11px] leading-[18px]" />
    <FieldError className="mt1 text-sm font-light text-error" />
  </BaseTextField>
)

export default TextField
