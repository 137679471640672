import { Parser } from 'expr-eval';

/** @see {@link https://github.com/silentmatt/expr-eval/pull/261} */
export type ExprEvalValue =
  | undefined
  | number
  | string
  | boolean
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type -- We don't care about param and return types
  | Function
  | ExprEvalValue[]
  | ExprEvalValues;

export type ExprEvalValues = { [k: string]: ExprEvalValue };

// eslint-disable-next-line @typescript-eslint/unbound-method -- We don't care about `this` in this case
const evaluate = Parser.evaluate as (
  expression: string,
  values?: ExprEvalValues,
) => ExprEvalValue;

export const createExprEval =
  (baseValues?: ExprEvalValues) =>
  (expression: string, values?: ExprEvalValues): ExprEvalValue =>
    evaluate(expression, { ...baseValues, ...values });
