import getAsset from '../../getAsset'
import { Immutable } from '@orangelv/utils'
import { ProductRow } from './types'

export const createMeta = ({
  productDict,
}: {
  productDict: Record<string, Immutable<ProductRow>>
}) => ({
  getIndexTitle: (config: Record<string, unknown>) => config.titleBase,

  getIndexDescription: (
    config: Record<string, unknown> & {
      getIndexDescription: () => string
    },
  ) => config.getIndexDescription(),

  getSkuValues: (sku: string) => ({
    canonicalSku: sku,
  }),

  getSkuTitle: (
    config: Record<string, unknown>,
    params: (string | number)[],
  ) => {
    const sku = params[0]
    const product = productDict[sku]
    if (!product) {
      throw new Error(`No product with SKU '${sku}' found.`)
    }
    if (!product.isEnabled) {
      throw new Error(`Product with SKU '${sku}' is not enabled.`)
    }
    return `${product.name} - ${config.titleBase}`
  },

  getSkuDescription: () => 'Build your own based on this template.',

  getRecipeTitle: (
    config: Record<string, unknown>,
    recipe: Record<string, unknown>,
    id: string,
    hasChanges: boolean,
  ) => `${id}${hasChanges ? ' with changes' : ''} - ${config.titleBase} `,

  getRecipeDescription: (
    config: Record<string, unknown> & {
      getRecipeDescription: () => string
    },
  ) => config.getRecipeDescription(),

  getStaticTags: () => `
  <link rel="shortcut icon" href="${getAsset('favicon.ico')}">
`,
})
