import {
  type AllOrNothing,
  hasLengthAtLeast,
  type PartialWithUndefined,
  type Tuple,
} from '@orangelv/utils';
import {
  add2,
  angle2,
  distance2,
  divide2,
  magnitude2,
  multiply2,
  radiansToDegrees,
  rotate2,
  subtract2,
  type Vector2,
} from '@orangelv/utils-geometry';
import {
  type SvgData,
  svgRotate,
  svgScale,
  svgTranslate,
} from '@orangelv/utils-svg';
import {
  boundingBoxToQuad,
  getIntersectedCommandEndpoints,
  getPathDataBoundingBox,
  getPathDataControlPointBoundingBox,
  intersectPathDataWithLine,
  type MinimalCommand,
  openTypePathToSvgPathData,
  parseSvgPathData,
  projectPathData,
  simplifyPathData,
  splicePathData,
  stringifySvgPathData,
} from '@orangelv/utils-svg-path-data';
import type { Element, Root } from 'hast';
import { select } from 'hast-util-select';
import type { Font, Glyph } from 'opentype.js';

import { getCapMetrics } from './text.js';

type Tail = { svg: SvgData } & PartialWithUndefined<{
  isConnected: boolean;
}> &
  AllOrNothing<{
    text: string;
    textColor: string;
    textFont: Font;
  }>;

export function createTail(
  tail: Tail,
  baseline: Tuple<Vector2, 2>,
  margin: number,
  height: number,
  glyph: Glyph,
  glyphPathData: MinimalCommand[],
  parentFontScale: number,
): [tailPathData: MinimalCommand[], tailText: Root | Element | undefined] {
  const { isConnected = false, svg, text, textColor, textFont } = tail;

  const tailPathDataString = select('path', svg.root)?.properties['d'];
  if (typeof tailPathDataString !== 'string') {
    throw new TypeError(`Bad tail SVG ${svg.name}`);
  }

  // Why transform ourselves, instead of "scale()" and "skew()"? Because the
  // outlines scale with the shape, and "vector-effect='non-scaling-stroke'"
  // that counteracts this isn't available in Prince [0]. Librsvg-based
  // libraries like sharp and node-canvas don't support it either [1]. Canvg has
  // it though [2].
  //
  // Also, we also do splicing now when the tail is connected, and projecting it
  // ourselves makes the math much simpler.
  //
  // [0] https://www.princexml.com/forum/topic/4714/support-for-vector-effect-non-scaling-stroke
  // [1] https://gitlab.gnome.org/GNOME/librsvg/-/issues/885
  // [2] https://github.com/canvg/canvg/pull/1459

  let tailPathData = simplifyPathData(parseSvgPathData(tailPathDataString));
  tailPathData = projectPathData(
    tailPathData,
    boundingBoxToQuad(getPathDataBoundingBox(tailPathData)),
    {
      bottomLeft: add2(baseline[0], { x: 0, y: margin + height }),
      bottomRight: add2(baseline[1], { x: 0, y: margin + height }),
      topLeft: add2(baseline[0], { x: 0, y: margin }),
      topRight: add2(baseline[1], { x: 0, y: margin }),
    },
  );

  let tailText;
  if (text !== undefined) {
    const tailTextPathData = openTypePathToSvgPathData(
      textFont.getPath(text, 0, 0, textFont.unitsPerEm),
    );
    const tailTextBoundingBox = getPathDataBoundingBox(tailTextPathData);

    // https://commons.wikimedia.org/wiki/File:Typography_Line_Terms.svg
    //
    // Why `cap.height` instead of the actual height? Because we want to
    // vertically-align the text in a stable manner, where the vertical features
    // of the text (capital letters, f, g, etc.) don't affect its alignment.
    //
    // To make sure that the text doesn't poke through the tail or touch its
    // bounds we add some padding in the form of a fraction of `cap.height`,
    // which is nice and uniform across all sorts of typefaces. Fonts also have
    // `sTypo*` fields in their `os2` table [0], but some fonts have bad values,
    // especially the capitals-only ones.
    //
    // Some fonts might have glyphs that descend further than this padding, but
    // it's rare, and is actually desirable, as otherwise the text would get too
    // small to accommodate these features. If poking-through becomes an issue,
    // a clipping mask may be employed.
    //
    // What's this diagonal? It's a vector representing the offset needed to get
    // from the top left corner to the bottom right corner of the text box.
    //
    // [0] https://docs.microsoft.com/en-us/typography/opentype/spec/os2

    const cap = getCapMetrics(textFont);
    const padding = 0.5 * cap.height;
    const sourceDiagonal = {
      x: tailTextBoundingBox.width + padding,
      y: cap.height + padding,
    };
    const diagonalAngle = -angle2({ x: 0, y: 0 }, sourceDiagonal);

    // https://github.com/OrangeLV/blocks/assets/2584727/6c26cf7f-1992-4b0f-b42c-cb0a43adf3b1
    //
    // Open this ^ "diagram". The algorithm is super ad hoc, but it's simple and
    // works well enough.
    //
    // Each iteration below evaluates the yellow crosses from right to left. The
    // yellow crosses are found by first finding the middle between the points
    // where the two green lines intersect the tail, and then the yellow cross
    // itself is the middle between those two middle points.
    //
    // Two lines (blue in the image) are shot through this yellow cross, at the
    // angle alpha to to the segment it's the middle of (see above). The angle
    // alpha is equal to the angle of the diagonal of the text box to the X
    // axis. We take the points where these blue lunes intersect the tail, and
    // discard all of them except for the one that's the closest to the yellow
    // cross (so we choose the shortest segment between a, b, c and d). This
    // distance - between the yellow cross and the closest intersection
    // - represents a half of the diagonal of the largest text box we can fit
    // here, with its center in the yellow cross.
    //
    // We go through all yellow crosses, and find the one where the text box
    // would be the largest. We give priority to the candidates more to the left
    // by having a loose epsilon when comparing to the previous best candidate
    // (so, if it's 1% worse but more to the left - it's better). Why prefer
    // left? Because it looks better when the text is left-aligned, so better
    // stick to the left unless it's impossible.
    //
    // This could be improved by evaluating more candidates, at potentially
    // better locations. The easiest one is probably to connect the "middles" of
    // non-adjacent green lines. Another one would be to evaluate some neighbors
    // on the normal that goes through the existing yellow crosses. A lot of
    // room for heuristics.
    //
    // Could this be solved numerically? Haven't researched, but I don't think
    // so. Could a genetic algorithm be employed? Most likely, but I don't think
    // it's worth the complexity.
    //
    // There's an edge case, where the intersections might report a good fit,
    // but the curve between those intersections goes toward the yellow cross,
    // basically putting a part of the text outside the tail, but, thankfully,
    // the tails we have don't have that kind of curves. This is solvable, but
    // isn't worth the complexity.

    const baselineWidth = baseline[1].x - baseline[0].x;
    const step = baselineWidth / 64;
    let previous;
    let finalist;
    for (let { x } = baseline[1]; x >= baseline[0].x; x -= step) {
      // The green line.
      const [a, b, ...c] = intersectPathDataWithLine(tailPathData, [
        { x, y: 0 },
        { x, y: 1 },
      ]);
      if (!a || !b || c.length > 0) continue;

      // The middle between the points where the green line intersects the tail.
      const current = divide2(add2(a.point, b.point), 2);
      if (!previous) {
        previous = current;
        continue;
      }

      // The yellow cross.
      const candidate = divide2(add2(previous, current), 2);

      // The points where the blue lines intersect the tail.
      const diagonalIntersections = [
        ...intersectPathDataWithLine(tailPathData, [
          candidate,
          rotate2(current, diagonalAngle, candidate),
        ]),
        ...intersectPathDataWithLine(tailPathData, [
          candidate,
          rotate2(current, -diagonalAngle, candidate),
        ]),
      ];

      // No filtering is done to detect the yellow crosses that lie outside the
      // tail. It's improbable with the current tail geometries and this simple
      // algorithm. However, if it becomes an issue, it can be done by binning
      // the intersection points per each ray (a, b, c and d) and making sure
      // there's an odd amount of intersections in each bin, and each bin has at
      // least one. This isn't implemented because edge cases require special
      // consideration, e.g. rays going through vertices and registering as 2
      // intersections, or 0, etc.

      // Here we just select the closest intersection. We can't "enlarge" the
      // text box past this point, otherwise it'll poke out of the tail.
      const candidateDiagonal =
        2 *
        diagonalIntersections.reduce((r, { point }) => {
          const d = distance2(candidate, point);
          return Math.min(d, r);
        }, Number.POSITIVE_INFINITY);

      // As mentioned above, we don't just do `candidateDiagonal >
      // finalist.diagonal` because it's preferable to have the text appear more
      // to the left, even if it's a little bit smaller. This simple fitness
      // test gives a "boost" to the candidates that are more to left, ranging
      // from ~0% for the rightmost candidate to ~20% for the leftmost.
      const fitness =
        candidateDiagonal * (1 + (0.2 * (baseline[1].x - x)) / baselineWidth);
      if (!finalist || fitness > finalist.fitness) {
        finalist = {
          angle: angle2(current, candidate),
          diagonal: candidateDiagonal,
          fitness,
          point: candidate,
        };
      }

      previous = current;
    }

    if (!finalist) throw new Error('Failed to find tail text position');

    tailText = (
      <path
        d={stringifySvgPathData(tailTextPathData)}
        fill={textColor}
        transform={
          svgTranslate(finalist.point.x, finalist.point.y) +
          svgScale(
            finalist.diagonal / magnitude2(sourceDiagonal),
            finalist.diagonal / magnitude2(sourceDiagonal),
          ) +
          svgRotate(radiansToDegrees(finalist.angle)) +
          svgTranslate(
            -tailTextBoundingBox.width / 2,
            cap.height / 2 - cap.undershoot,
          )
        }
      />
    );
  }

  if (isConnected) {
    if (
      glyph.advanceWidth === undefined ||
      glyph.leftSideBearing === undefined
    ) {
      throw new Error('Bad glyph');
    }

    // Fonts use control point bounding boxes [0]. For performance,
    // presumably. Glyph overlap is defined by advance and bearing [1]. We
    // ignore letter spacing and kerning, because we don't actually have
    // another glyph, we're just looking for the tail. If we know the origin
    // of the current glyph, then we can deduce the origin of the next one by
    // just adding "advance". The left side of the bounding box of the next
    // glyph then will be at the "new origin" + "bearing". We take current
    // glyph's bearing, since we don't have another one. It should be uniform
    // across the font anyway.
    //
    // [0] https://docs.microsoft.com/en-us/typography/opentype/spec/glyf#glyph-headers
    // [1] https://freetype.org/freetype2/docs/glyphs/glyphs-3.html#section-3

    const cpbb = getPathDataControlPointBoundingBox(glyphPathData);
    const bearing = glyph.leftSideBearing * parentFontScale;
    const advance = glyph.advanceWidth * parentFontScale;
    const connectionX = cpbb.left - bearing + advance + bearing;
    const connectionVertical = [
      { x: connectionX, y: 0 },
      { x: connectionX, y: 1 },
    ] as const;

    const glyphIntersections = intersectPathDataWithLine(
      glyphPathData,
      connectionVertical,
    )
      .sort((a, b) => a.point.y - b.point.y)
      .slice(-2);

    if (!hasLengthAtLeast(glyphIntersections, 2)) {
      throw new Error("Failed to find glyph's tail");
    }

    const tailIntersections = intersectPathDataWithLine(
      tailPathData,
      connectionVertical,
    )
      .sort((a, b) => a.point.y - b.point.y)
      .slice(0, 2);

    if (!hasLengthAtLeast(tailIntersections, 2)) {
      throw new Error("Failed to find tail's end");
    }

    const [glyphTop, glyphBottom] = getIntersectedCommandEndpoints(
      glyphPathData,
      glyphIntersections,
    );
    const [tailTop, tailBottom] = getIntersectedCommandEndpoints(
      tailPathData,
      tailIntersections,
    );

    // These are arbitrary, adjust to taste. Can be different for all four
    // control points too, this is just good enough.
    const outerControlMagnitude = distance2(glyphTop.point, tailBottom.point);
    const innerControlMagnitude = distance2(glyphBottom.point, tailTop.point);

    const tailTopControl = add2(
      tailTop.point,
      multiply2(innerControlMagnitude, tailTop.tangent),
    );
    const glyphBottomControl = add2(
      glyphBottom.point,
      multiply2(innerControlMagnitude, glyphBottom.tangent),
    );
    const glyphRoundingBottomControl = subtract2(
      glyphBottom.point,
      glyphBottom.tangent,
    );
    const glyphRoundingTopControl = subtract2(glyphTop.point, glyphTop.tangent);
    const glyphTopControl = add2(
      glyphTop.point,
      multiply2(outerControlMagnitude, glyphTop.tangent),
    );
    const tailBottomControl = add2(
      tailBottom.point,
      multiply2(outerControlMagnitude, tailBottom.tangent),
    );

    const isZFirst = tailIntersections[0].index === tailPathData.length - 1;
    const closer: MinimalCommand[] =
      tailIntersections[0].index < tailIntersections[1].index || isZFirst ?
        []
      : [{ type: 'Z', values: [] }];

    tailPathData = splicePathData(
      tailPathData,
      isZFirst ? 0 : tailIntersections[0].index,
      tailIntersections[1].index,
      [
        {
          type: 'C',
          values: [
            tailTopControl.x,
            tailTopControl.y,
            glyphBottomControl.x,
            glyphBottomControl.y,
            glyphBottom.point.x,
            glyphBottom.point.y,
          ],
        },
        // Why the overlap? Anti-aliasing isn't perfect, and if the physical
        // pixel alignment is off then the background may peek through. Why the
        // rounding? Because the sharp edge of the tail can poke through the
        // curve, because this isn't a continuous path, and the corner gets a
        // regular sharp miter join.
        {
          type: 'C',
          values: [
            glyphRoundingBottomControl.x,
            glyphRoundingBottomControl.y,
            glyphRoundingTopControl.x,
            glyphRoundingTopControl.y,
            glyphTop.point.x,
            glyphTop.point.y,
          ],
        },
        {
          type: 'C',
          values: [
            glyphTopControl.x,
            glyphTopControl.y,
            tailBottomControl.x,
            tailBottomControl.y,
            tailBottom.point.x,
            tailBottom.point.y,
          ],
        },
        // Re-close the path if we splice over it
        ...closer,
      ],
    );
  }

  return [tailPathData, tailText];
}
