import { Immutable } from '@orangelv/utils'
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useMemo,
  useState,
} from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { PostHogProvider } from 'posthog-js/react'

import { loadRecipe, setOriginValues } from '../../client/common/actions'
import { isSkuSelector, matchesSelector } from '../../client/common/selectors'
// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import configureRouter from '../../client/configureRouter'
import configureStore from '../../client/configureStore'
// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { createControlTree } from '../../client/control-tree'
import { createUtils } from '../../client/control-tree/debugUtils'
import FileUpload from '../../client/control-tree/fileUpload'
import Repeater from '../../client/control-tree/repeater'
import Select from '../../client/control-tree/select'
import Text from '../../client/control-tree/text'
import { loadFonts } from '../common/fonts'
import { SelectOption } from './components/SelectTile'
import {
  ColorRow,
  CustomFontRow,
  DesignPartRow,
  DesignRow,
  FabricRow,
  FillRow,
  FontRow,
  PlacementRow,
  ProductRow,
  TextDecoration,
  VendorRow,
} from '../common/types'
import App from './components/App'
import generateFullCustomTemplates from './generate-full-custom-templates'

import './index.css'
import { PostHogConfig } from 'posthog-js'
// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { MAX_DESIGN_COLORS } from '../common/consts.js'
import { ControlTree } from '../../client/control-tree/types'

const getPlacementScaleFactorOptions = (
  placement: Immutable<PlacementRow>,
): SelectOption[] => {
  if (!placement.scaleFactors || !placement.scaleFactorNames) return []

  const scaleFactorNames = placement.scaleFactorNames

  return placement.scaleFactors.map((scaleFactor, i) => ({
    name: scaleFactorNames[i],
    id: scaleFactor,
  }))
}

const getProductPlacementRow = (
  placements: Immutable<PlacementRow[]>,
  productId: string,
  decoration: string,
  pieceName?: string,
): Immutable<PlacementRow> | undefined =>
  placements.find(
    (placement) =>
      placement.decoration === decoration &&
      placement.productId === productId &&
      (pieceName === undefined || placement.pieceName === pieceName),
  )

const createScaleFactorNode = (
  placements: Immutable<PlacementRow[]>,
  features: Immutable<VendorRow>['features'],
  decoration: string,
  pieceName?: string | undefined,
) =>
  Select({
    dependencies: ['sku'],
    isAvailable: (sku) => {
      const placement = getProductPlacementRow(
        placements,
        sku.id,
        decoration,
        pieceName,
      )

      return !!(
        features.textSize &&
        placement?.scaleFactors &&
        placement.scaleFactors.length > 0
      )
    },
    options: (sku: { id: string }) => {
      const placement = getProductPlacementRow(
        placements,
        sku.id,
        decoration,
        pieceName,
      )

      return placement ? getPlacementScaleFactorOptions(placement) : []
    },
    defaultValue: (sku: { id: string }) => {
      const placement = getProductPlacementRow(
        placements,
        sku.id,
        decoration,
        pieceName,
      )

      if (!placement) return null

      const options = getPlacementScaleFactorOptions(placement)

      if (options.length === 0) return null

      return options[options.length - 1].id
    },
  })

const createTextPlacement = (
  { features, defaults }: Immutable<VendorRow>,
  name: TextDecoration,
  colors: Immutable<ColorRow[]>,
  placements: Immutable<PlacementRow[]>,
) => ({
  text: Text({
    isAvailable: () => features[name],
    defaultValue: defaults?.[name]?.text,
  }),

  font: Text({
    dependencies: [`${name}.text`],
    defaultValue: defaults?.[name]?.font,
  }),

  scaleFactor: createScaleFactorNode(placements, features, name),

  color: Select({
    options: colors,
    defaultValue: defaults?.[name]?.colorId,
  }),

  outline1Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline1ColorId ?? null,
  }),

  outline2Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline2ColorId ?? null,
  }),
})

const createAreaColorNames = (
  area: string,
  colors: Immutable<ColorRow[]>,
  features: Immutable<VendorRow>['features'],
) =>
  Array.from({ length: MAX_DESIGN_COLORS }, (_, i) => [
    `${area}.color${i + 1}`,
    Select({
      dependencies: ['sku'],
      options: colors,
      isAvailable: (sku) =>
        features.productColor && sku.colorAreas.includes(area),
    }),
  ])

const getColorAreas = (enabledProducts: Immutable<ProductRow[]>) =>
  Array.from(
    new Set(enabledProducts.flatMap((product) => product.colorAreas)),
  ).filter(Boolean) as string[] // TODO: remove cast when updated to TS 5.5

/*
 * Creates color nodes (e.g. sleevesColor) from the product colorAreas defined in sheets
 */
const createProductColors = (
  colors: Immutable<ColorRow[]>,
  enabledProducts: Immutable<ProductRow[]>,
  features: Immutable<VendorRow>['features'],
) =>
  Object.fromEntries(
    getColorAreas(enabledProducts).map((area) => [
      `${area}Color`,
      Select({
        dependencies: ['sku'],
        options: colors,
        isAvailable: (sku) =>
          features.productColor && sku.colorAreas.includes(area),
      }),
    ]),
  )

/*
 * Creates fill nodes from the product colorAreas defined in sheets
 * Example structure:
 * 'sleeves.fill: ...,
 * 'sleeves.color1': ...,
 * 'sleeves.color2': ...,
 * 'sleeves.color3': ...,
 * 'sleeves.color4': ...,
 */
const createProductFills = (
  colors: Immutable<ColorRow[]>,
  fills: Immutable<FillRow[]>,
  enabledProducts: Immutable<ProductRow[]>,
  features: Immutable<VendorRow>['features'],
) =>
  Object.fromEntries(
    getColorAreas(enabledProducts).flatMap((area) => [
      [
        `${area}.fill`,
        Select({
          dependencies: ['sku'],
          options: fills,
          isAvailable: (sku) => features.fill && sku.colorAreas.includes(area),
        }),
      ],
      ...createAreaColorNames(area, colors, features),
    ]),
  )

const createPlayersTextStyle = (
  name: TextDecoration,
  colors: Immutable<ColorRow[]>,
  features: Immutable<VendorRow>['features'],
  defaults: Immutable<VendorRow>['defaults'],
  placements: Immutable<PlacementRow[]>,
) => ({
  font: Text({ defaultValue: defaults?.[name]?.font }),

  color: Select({ options: colors, defaultValue: defaults?.[name]?.colorId }),

  scaleFactor: createScaleFactorNode(placements, features, name),

  outline1Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline1ColorId ?? null,
  }),

  outline2Color: Select({
    isAvailable: () => features.outline,
    options: colors,
    isRequired: false,
    defaultValue: defaults?.[name]?.outline2ColorId ?? null,
  }),
})

const createPlayers = (
  colors: Immutable<ColorRow[]>,
  features: Immutable<VendorRow>['features'],
  defaults: Immutable<VendorRow>['defaults'],
  placements: Immutable<PlacementRow[]>,
) => ({
  nameStyle: createPlayersTextStyle(
    'playerName',
    colors,
    features,
    defaults,
    placements,
  ),
  numberStyle: {
    font: Text({ defaultValue: defaults?.playerNumber?.font }),

    color: Select({
      options: colors,
      defaultValue: defaults?.playerNumber?.colorId,
    }),

    scaleFactor: {
      front: createScaleFactorNode(
        placements,
        features,
        'playerNumber',
        'front',
      ),
      back: createScaleFactorNode(placements, features, 'playerNumber', 'back'),
    },

    outline1Color: Select({
      isAvailable: () => features.outline,
      options: colors,
      isRequired: false,
      defaultValue: defaults?.playerNumber?.outline1ColorId ?? null,
    }),

    outline2Color: Select({
      isAvailable: () => features.outline,
      options: colors,
      isRequired: false,
      defaultValue: defaults?.playerNumber?.outline2ColorId ?? null,
    }),
  },
  roster: Repeater({
    defaultRepeats: 1,
    controls: {
      name: Text({
        dependencies: ['sku'],
        isAvailable: (product: ProductRow) =>
          placements.some(
            (placement) =>
              placement.decoration === 'playerName' &&
              placement.productId === product.id,
          ),
        defaultValue: defaults?.playerName?.text,
      }),
      number: Text({
        dependencies: ['sku'],
        isAvailable: (product: ProductRow) =>
          placements.some(
            (placement) =>
              placement.decoration === 'playerNumber' &&
              placement.productId === product.id,
          ),
        defaultValue: defaults?.playerNumber?.text,
      }),
      size: Select({
        dependencies: ['sku'],
        // not sure why we need "options" according to TS types
        options: [],
        visibleOptions: (product: ProductRow) =>
          (product.sizes ?? []).map((size) => ({ id: size, name: size })),
        defaultValue: (product: ProductRow) => product.defaultSize,
      }),
    },
  }),
})

export const AppContext = createContext<
  Immutable<{
    patternPackage: string
    vendor: VendorRow
    products: ProductRow[]
    designs: DesignRow[]
    controlTree: ControlTree
    designParts: DesignPartRow[]
    colorDict: Record<string, ColorRow>
    fillDict: Record<string, FillRow>
    fonts: FontRow[]
    customFonts: CustomFontRow[]
    placements: PlacementRow[]
  }>
>(null as any) // https://stackoverflow.com/a/67957976/242684

export const LoadingContext = createContext<{
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}>({
  isLoading: true,
  setIsLoading: () => {},
})

export const RosterContext = createContext<{
  playerBeingPreviewed: number
  setPlayerBeingPreviewed: Dispatch<SetStateAction<number>>
}>({
  playerBeingPreviewed: 1,
  setPlayerBeingPreviewed: () => {},
})

const AppContextProvider = ({
  children,
  initialValues,
}: PropsWithChildren<{ initialValues: any }>): JSX.Element => {
  const value = useMemo(() => ({ ...initialValues }), [initialValues])
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const LoadingContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading])
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

const RosterContextProvider: React.FC<{
  children: React.ReactNode
  initialPlayerBeingPreviewed?: number
}> = ({ children, initialPlayerBeingPreviewed = 1 }) => {
  const [playerBeingPreviewed, setPlayerBeingPreviewed] = useState(
    initialPlayerBeingPreviewed,
  )
  const value = useMemo(
    () => ({ playerBeingPreviewed, setPlayerBeingPreviewed }),
    [playerBeingPreviewed],
  )
  return (
    <RosterContext.Provider value={value}>{children}</RosterContext.Provider>
  )
}

export function createApp({
  patternPackage,
  vendors,
  products,
  fabrics,
  designs,
  designParts,
  colors,
  colorDict,
  fills,
  fillDict,
  fonts,
  customFonts,
  placements,
}: Immutable<{
  patternPackage: string
  vendors: VendorRow[]
  products: ProductRow[]
  fabrics: FabricRow[]
  designs: DesignRow[]
  designParts: DesignPartRow[]
  colors: ColorRow[]
  colorDict: Record<string, ColorRow>
  fills: FillRow[]
  fillDict: Record<string, FillRow>
  fonts: FontRow[]
  customFonts: CustomFontRow[]
  placements: PlacementRow[]
}>) {
  const vendor = vendors.find(
    (x) => x.hostname === window.serverConfig?.hostname,
  )
  if (!vendor) throw new Error('Bad or missing window.serverConfig.hostname')

  const { features, defaults } = vendor

  // Kick off font loading asap!
  loadFonts(vendor, fonts, customFonts)

  const enabledProducts = products.filter((x) => x.isEnabled)

  const controlTree = createControlTree({
    sku: Select({
      options: products,
      visibleOptions: enabledProducts,
      defaultValue: enabledProducts[0].id,
    }),

    fabric: Select({
      dependencies: ['sku'],
      options: fabrics,
      isAvailable: () => features.fabric,
      visibleOptions: (product: ProductRow) =>
        product.fabrics?.map((x) => fabrics.find((y) => x === y.id)),
    }),

    design: {
      design: Select({
        dependencies: ['sku'],
        isRequired: false,
        isAvailable: () => features.design,
        options: designs,
        visibleOptions: (product: ProductRow) =>
          designs.filter(
            (x) =>
              x.limitations.productIds.includes(product.id) &&
              (x.id !== 'fullCustom' || features.fullCustom),
          ),
      }),

      color1: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 1,
        defaultValue: defaults?.designColor1Id,
      }),

      color2: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 2,
        defaultValue: defaults?.designColor2Id,
      }),

      color3: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 3,
        defaultValue: defaults?.designColor3Id,
      }),

      color4: Select({
        dependencies: ['design.design'],
        options: colors,
        isAvailable: (design: DesignRow | null) =>
          features.designColor && !!design && design.colors >= 4,
        defaultValue: defaults?.designColor4Id,
      }),

      file: FileUpload({
        dependencies: ['design.design'],
        isAvailable: (design: DesignRow | null) => design?.id === 'fullCustom',
        isRequired: false,
      }),
    },

    brandLogoColor: Select({
      options: colors,
      isAvailable: () => features.brandLogo,
      defaultValue: defaults?.brandLogoColorId,
    }),

    ...createProductColors(colors, enabledProducts, features),

    ...createProductFills(colors, fills, enabledProducts, features),

    teamName: createTextPlacement(vendor, 'teamName', colors, placements),

    players: createPlayers(colors, features, defaults, placements),

    teamLogo: FileUpload({
      dependencies: ['sku'],
      isAvailable: (product: ProductRow) =>
        features.teamLogo &&
        placements.find((placement) => placement.decoration === 'teamLogo')
          ?.productId === product.id,
    }),
  })

  const store = configureStore({ updater: controlTree.updater, controlTree })

  for (const [name, fn] of Object.entries(createUtils(store, controlTree))) {
    window[name] = fn
  }

  window['generateFullCustomTemplates'] = generateFullCustomTemplates

  const routes = [
    '/sku/:sku',
    '/design/:recipeId(/:status)',
    '/review/:recipeId',
  ]

  const { initialMatches } = configureRouter(controlTree, store, routes)

  const initialState = store.getState()

  const getValuesFromSku = (sku: string) => {
    const values = {
      sku,
    }

    return values
  }

  if (initialMatches?.recipeId) {
    store.dispatch(loadRecipe(controlTree, initialMatches.recipeId))
  } else if (initialMatches && isSkuSelector(initialState)) {
    const { sku } = initialMatches
    store.dispatch(controlTree.setValues(getValuesFromSku(sku)))
    store.dispatch(setOriginValues(controlTree))
  } else {
    store.dispatch(setOriginValues(controlTree))
  }

  let sku: string
  store.subscribe(() => {
    const matches = matchesSelector(store.getState())
    if (matches?.sku === undefined || matches.sku === sku) return
    sku = matches.sku
    store.dispatch(controlTree.commitChanges())
    store.dispatch(controlTree.setValues({ sku }))
  })

  const posthogConfig = window['postHogConfig']

  const posthogOptions: Partial<PostHogConfig> = {
    person_profiles: posthogConfig.personProfiles,
    api_host:
      posthogConfig.apiHost !== '' ?
        posthogConfig.apiHost
      : 'https://us.i.posthog.com',
    ui_host: 'https://us.posthog.com',
  }

  createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
      <PostHogProvider apiKey={posthogConfig.apiKey} options={posthogOptions}>
        <AppContextProvider
          initialValues={{
            patternPackage,
            vendor,
            products,
            designs,
            controlTree,
            designParts,
            colorDict,
            fillDict,
            fonts,
            customFonts,
            placements,
          }}
        >
          <LoadingContextProvider>
            <RosterContextProvider>
              <App />
            </RosterContextProvider>
          </LoadingContextProvider>
        </AppContextProvider>
      </PostHogProvider>
    </Provider>,
  )

  return { controlTree, store }
}
