import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { s } from 'hastscript'

import { SvgData, svgToString, svgTranslate } from '@orangelv/utils-svg'
import { Vector2 } from '@orangelv/utils-geometry'

import assert from '../../assert'
import { PRODUCTS as PRODUCTS_CLAW } from '../../../customizers/claw-teamwear/common/sheets'
import { PRODUCTS as PRODUCTS_DEMO } from '../../../customizers/olv-demo/common/sheets'
import { PRODUCTS as PRODUCTS_SMACK } from '../../../customizers/smack-sportswear/common/sheets'
// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { PATTERN_PACKAGE } from '../common/consts'
import { loadPattern } from '../../client/svg-renderer-utils'
import { getSize } from './bjs-renderer-utils'

// This file is an evolution of Mizuno Apparel's original generator (simpler
// offset math and piece arrangement logic).

const PRODUCTS = [...PRODUCTS_CLAW, ...PRODUCTS_DEMO, ...PRODUCTS_SMACK]

const PADDING_X = 200
const PADDING_Y = 200
const INFO_HEIGHT = 292 + PADDING_Y
const FONT_SIZE = 24
const ILLUSTRATOR_MAX_CANVAS_SIZE = { width: 16_383, height: 16_383 }

const outputType: unknown | 'zip' | 'browser' = 'zip'

type PieceInfoByProduct = Record<
  string,
  Record<string, Record<string, Awaited<ReturnType<typeof getSize>>>>
>

type PieceOffsets = Record<string, Record<string, Vector2>>

type Output = {
  offsets: PieceOffsets
  svgs: Record<string, string>
}

const pointsToPathD = (points: Vector2[]) =>
  'M ' + points.map(({ x, y }) => `${x} ${y}`).join(' ') + ' Z'

const outputZip = async (output: Output) => {
  const zip = new JSZip()

  for (const productId of Object.keys(output.svgs)) {
    zip.file(`${productId}-template.svg`, output.svgs[productId])
  }

  zip.file('offsets.json', JSON.stringify(output.offsets, null, 2))

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'full-custom-templates.zip')
}

const outputBrowser = (output: Output) => {
  for (const productId of Object.keys(output.svgs)) {
    const debugWindow = window.open()

    if (!debugWindow) {
      console.log(
        'ERROR: Cannot open a new tab! Make sure you have allowed pop-up windows in browser settings.',
      )
      return
    }

    const debugDocument = debugWindow.document

    debugDocument.write(`<title>${productId} Full Custom Template</title>`)
    debugDocument.write(output.svgs[productId])
  }

  const debugWindow = window.open()

  assert(debugWindow)

  debugWindow.document.write('<title>offsets.json</title>')
  debugWindow.document.write(
    `<pre>${JSON.stringify(output.offsets, null, 2)}</pre>`,
  )
}

const strokeAttrs = {
  stroke: 'black',
  strokeWidth: '4',
  strokeLinecap: 'round',
  strokeDasharray: '5,5',
}

const getHiddenId = (() => {
  let index = 0
  return () => {
    const id = `hidden${index}`
    index += 1
    return id
  }
})()

type SizePieceInfo = Record<string, Awaited<ReturnType<typeof getSize>>>

const generateFullCustomTemplates = async () => {
  console.time('generateFullCustomTemplates')

  const availableProducts = PRODUCTS.filter((x) => x.isEnabled)

  const output: Output = {
    svgs: {},
    offsets: {},
  }

  const pieceInfoByProduct: PieceInfoByProduct = {}
  const pieceOffsets: PieceOffsets = {}

  for (const product of availableProducts) {
    const pattern = await loadPattern(PATTERN_PACKAGE, product.id)

    const largestSize = pattern.sizeNames.at(-1)
    assert(largestSize !== undefined)

    const currentProductPieceInfo: Record<string, SizePieceInfo> = {}
    pieceInfoByProduct[product.id] = currentProductPieceInfo
    for (const size of [pattern.sampleSizeName, largestSize]) {
      const sizePieceInfo: SizePieceInfo = {}
      currentProductPieceInfo[size] = sizePieceInfo

      for (const pieceMappingItem of pattern.pieceMapping) {
        sizePieceInfo[pieceMappingItem.name] = await getSize(
          PATTERN_PACKAGE,
          pattern,
          product.id,
          pieceMappingItem,
          size,
        )
      }
    }

    const pieceInfo = pieceInfoByProduct[product.id]?.[largestSize]

    assert(pieceInfo, 'Piece info not found!')

    pieceOffsets[product.id] = {}
    let documentWidth = 0
    let workingPoint = { x: PADDING_X, y: INFO_HEIGHT + PADDING_Y }
    let rowHeight = 0
    for (const pieceMappingItem of pattern.pieceMapping) {
      const sizeWithMetrics = pieceInfo[pieceMappingItem.name]

      assert(sizeWithMetrics, 'Piece size not found!')

      rowHeight = Math.max(rowHeight, sizeWithMetrics.cutLineBox.height)

      pieceOffsets[product.id]![pieceMappingItem.name] = {
        x: workingPoint.x + sizeWithMetrics.cutLineBox.width / 2,
        y: workingPoint.y + sizeWithMetrics.cutLineBox.height / 2,
      }

      if (
        workingPoint.x + sizeWithMetrics.cutLineBox.width + PADDING_X <=
        ILLUSTRATOR_MAX_CANVAS_SIZE.width
      ) {
        workingPoint.x += sizeWithMetrics.cutLineBox.width + PADDING_X
      } else {
        workingPoint.x = PADDING_X
        workingPoint.y += rowHeight + PADDING_Y
        rowHeight = 0
      }

      documentWidth = Math.max(documentWidth, workingPoint.x)
    }

    documentWidth = Math.round(documentWidth)
    const documentHeight = Math.round(workingPoint.y + rowHeight + PADDING_Y)

    if (
      documentWidth > ILLUSTRATOR_MAX_CANVAS_SIZE.width ||
      documentHeight > ILLUSTRATOR_MAX_CANVAS_SIZE.height
    ) {
      console.log(
        `WARN: Document size (${documentWidth}x${documentHeight}) is larger than Illustrator max canvas size!`,
      )
    }

    output.offsets[product.id] = pieceOffsets[product.id]

    const children: ReturnType<typeof s>[] = []

    const textAttrs = {
      fill: 'black',
      style: `font: bold ${FONT_SIZE}px sans-serif; text-shadow: white 0 0 5px;`,
    }

    children.push(
      s(
        'g',
        {
          transform: svgTranslate(PADDING_X, PADDING_Y),
          id: getHiddenId(),
        },
        s('text', textAttrs, [
          s('tspan', { x: 0, dy: '0.8em' }, [
            s(
              'tspan',
              {},
              `This is a full custom template for ${product.name}.`,
            ),
          ]),
          s('tspan', { x: 0, dy: '1.6em' }, ' '),
          s(
            'tspan',
            { x: 0, dy: '1.6em' },
            'Place your design files or draw anything onto the piece boxes.',
          ),
          s('tspan', { x: 0, dy: '1.6em' }, [
            s('tspan', {}, 'Colored lines indicate approximate shape of '),
            s('tspan', { fill: 'red' }, largestSize),
            ...(largestSize !== pattern.sampleSizeName ?
              [
                s('tspan', {}, ' and '),
                s('tspan', { fill: 'green' }, pattern.sampleSizeName),
              ]
            : []),
            s('tspan', {}, ' size apparel.'),
          ]),
          s(
            'tspan',
            { x: 0, dy: '1.6em' },
            'Export as SVG file and upload it back to the customizer to see the design in 3D.',
          ),
          s('tspan', { x: 0, dy: '1.6em' }, ' '),
          s(
            'tspan',
            { x: 0, dy: '1.6em' },
            `Pieces: ${pattern.pieceMapping.map((x) => x.name).join(', ')}`,
          ),
        ]),
      ),
    )

    for (const pieceMappingItem of pattern.pieceMapping) {
      const pieceOffset = pieceOffsets[product.id]?.[pieceMappingItem.name]

      assert(pieceOffset, 'Piece offset not found!')

      for (const sizeId of Object.keys(pieceInfoByProduct[product.id])) {
        const sizeWithMetrics =
          pieceInfoByProduct[product.id]![sizeId][pieceMappingItem.name]

        if (sizeId === largestSize) {
          children.push(
            s('rect', {
              width: sizeWithMetrics.cutLineBox.width,
              height: sizeWithMetrics.cutLineBox.height,
              transform: svgTranslate(
                pieceOffset.x - sizeWithMetrics.cutLineBox.width / 2,
                pieceOffset.y - sizeWithMetrics.cutLineBox.height / 2,
              ),
              id: getHiddenId(),
              fill: 'none',
              ...strokeAttrs,
              strokeWidth: '4',
            }),
          )
        }

        children.push(
          s('path', {
            d: pointsToPathD(sizeWithMetrics.cutLine),
            transform: svgTranslate(
              pieceOffset.x -
                sizeWithMetrics.cutLineBox.width / 2 -
                sizeWithMetrics.cutLineBox.x,
              pieceOffset.y -
                sizeWithMetrics.cutLineBox.height / 2 -
                sizeWithMetrics.cutLineBox.y,
            ),
            id: getHiddenId(),
            fill: 'none',
            ...strokeAttrs,
            stroke: sizeId === largestSize ? 'red' : 'green',
          }),
        )
      }

      const largestPiece = pieceInfo[pieceMappingItem.name]

      assert(largestPiece, 'Largest piece not found!')

      children.push(
        s(
          'g',
          {
            transform: svgTranslate(
              pieceOffset.x - largestPiece.cutLineBox.width / 2,
              pieceOffset.y - largestPiece.cutLineBox.height / 2 - FONT_SIZE,
            ),
            id: getHiddenId(),
          },
          s('text', textAttrs, [
            s('tspan', {}, 'Piece: '),
            s(
              'tspan',
              {
                fontWeight: 'bolder',
              },
              pieceMappingItem.name,
            ),
          ]),
        ),
      )
    }

    const svgData: SvgData = {
      name: product.id,
      width: documentWidth,
      height: documentHeight,
      root: {
        type: 'root',
        children: [
          s(
            'svg',
            {
              xmlns: 'http://www.w3.org/2000/svg',
              'xmlns:xlink': 'http://www.w3.org/1999/xlink',
              width: `${documentWidth}px`,
              height: `${documentHeight}px`,
            },
            [s('g', { id: 'full-custom-template' }, children)],
          ),
        ],
      },
    }

    const svgString = svgToString(svgData)

    output.svgs[product.id] = svgString
  }

  if (outputType === 'zip') {
    outputZip(output)
  } else if (outputType === 'browser') {
    outputBrowser(output)
  }

  console.timeEnd('generateFullCustomTemplates')
}

export default generateFullCustomTemplates
