import { useEffect } from 'react'
import { sleep } from '@orangelv/utils'

const FontPreview = ({
  fontUrl,
  children,
}: {
  fontUrl: string
  children: string
}) => {
  // Both Firefox and Chrome have unique quirks in regard to family names, so
  // we just limit the character set and avoid needing to quote.
  const fontFamily = fontUrl.replace(/[^a-zA-Z0-9]/g, '-')

  useEffect(() => {
    const fontFace = new FontFace(fontFamily, `url(${encodeURI(fontUrl)})`)

    const load = async () => {
      try {
        return await fontFace.load()
      } catch (e) {
        // Never fail, always retry after a while.
        // The promise will be rejected on component cleanup.
        await sleep(1000)
        return load()
      }
    }

    document.fonts.add(fontFace)

    const promise = load()

    return () => {
      document.fonts.delete(fontFace)

      Promise.reject(promise).catch(() => {})
    }
  }, [fontFamily, fontUrl])

  return (
    // Why pb-px and fontSize? Because em-square quirks result in a 1-2px bias
    // toward the bottom, this works around it. No, it doesn't help to use
    // vertical-align or reset line-height.
    <div
      className="pb-px"
      style={{ fontFamily, fontSize: '19px' }}
      title={children}
    >
      {children}
    </div>
  )
}

export default FontPreview
