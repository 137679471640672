import { useAppDispatch } from '../../../../platform/client/configureStore'
// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { change } from '../../../../platform/client/control-tree'
import { useFonts } from '../fonts'
import { type AlertMessage } from './Alert'
import ColorTile from './ColorTile'
import FontTile from './FontTile'
import SelectTile from './SelectTile'
import TileSet from './TileSet'

type Props = {
  title?: string
  colorNode: any
  fontNode: { value: string | undefined; keyPath: string; isAvailable: boolean }
  outline1ColorNode: any
  outline2ColorNode: any
  setAlert: (alert: AlertMessage) => void
  isColorToneEnabled: boolean
  isOutlineEnabled: boolean
  scaleFactorNode?: any
  playerNumberFrontScaleFactorNode?: any
  playerNumberBackScaleFactorNode?: any
}

const TextStyleInput = ({
  colorNode,
  fontNode,
  isColorToneEnabled,
  isOutlineEnabled,
  outline1ColorNode,
  outline2ColorNode,
  setAlert,
  scaleFactorNode,
  playerNumberFrontScaleFactorNode,
  playerNumberBackScaleFactorNode,
  title,
}: Props) => {
  const dispatch = useAppDispatch()

  const fontId = fontNode.value

  const {
    fontFamilyItems,
    fontFamilyItemId,
    fontVariantItems,
    fontVariantItemId,
  } = useFonts(fontId)
  return (
    <TileSet>
      {title && <span className="font-bold">{title}</span>}
      {colorNode.isAvailable && (
        <ColorTile
          label="Color"
          colors={colorNode.options}
          color={colorNode.object}
          onSelected={(color) => {
            dispatch(change(colorNode.keyPath, color ? color.id : null))
          }}
          setAlert={setAlert}
          isColorToneEnabled={isColorToneEnabled}
        />
      )}
      {fontNode.isAvailable && (
        <>
          <FontTile
            iconName="fontFamily"
            switcherOn={false}
            title="Font Family"
            items={fontFamilyItems}
            itemId={fontFamilyItemId}
            onSelected={(x) => {
              dispatch(change(fontNode.keyPath, x.id))
            }}
            isSearchable={fontFamilyItems.length > 8}
          />

          {fontVariantItems.length > 1 && (
            <FontTile
              iconName="fontStyle"
              switcherOn={false}
              title="Font Style"
              items={fontVariantItems}
              itemId={fontVariantItemId}
              onSelected={(x) => {
                dispatch(change(fontNode.keyPath, x.id))
              }}
            />
          )}
          {scaleFactorNode?.isAvailable && (
            <SelectTile
              options={
                scaleFactorNode.visibleOptions || scaleFactorNode.options
              }
              value={scaleFactorNode.value}
              onChange={(value) => {
                dispatch(change(scaleFactorNode.keyPath, value))
              }}
              label={'Text Size'}
              iconName="textSize"
            />
          )}
          {playerNumberFrontScaleFactorNode?.isAvailable && (
            <SelectTile
              options={
                playerNumberFrontScaleFactorNode.visibleOptions ||
                playerNumberFrontScaleFactorNode.options
              }
              value={playerNumberFrontScaleFactorNode.value}
              onChange={(value) => {
                dispatch(
                  change(playerNumberFrontScaleFactorNode.keyPath, value),
                )
              }}
              label={'Front Number Size'}
              iconName="textSize"
            />
          )}
          {playerNumberBackScaleFactorNode?.isAvailable && (
            <SelectTile
              options={
                playerNumberBackScaleFactorNode.visibleOptions ||
                playerNumberBackScaleFactorNode.options
              }
              value={playerNumberBackScaleFactorNode.value}
              onChange={(value) => {
                dispatch(change(playerNumberBackScaleFactorNode.keyPath, value))
              }}
              label={'Back Number Size'}
              iconName="textSize"
            />
          )}
          {isOutlineEnabled &&
            (outline1ColorNode.isAvailable ||
              outline2ColorNode.isAvailable) && (
              <>
                {outline1ColorNode.isAvailable && (
                  <ColorTile
                    label="Outline 1"
                    colors={outline1ColorNode.options}
                    color={outline1ColorNode.object}
                    isOptional={!outline1ColorNode.isRequired}
                    onSelected={(color) => {
                      dispatch(
                        change(
                          outline1ColorNode.keyPath,
                          color ? color.id : null,
                        ),
                      )
                    }}
                    setAlert={setAlert}
                    isColorToneEnabled={isColorToneEnabled}
                  />
                )}
                {outline2ColorNode.isAvailable && (
                  <ColorTile
                    label="Outline 2"
                    colors={outline2ColorNode.options}
                    color={outline2ColorNode.object}
                    isOptional={!outline2ColorNode.isRequired}
                    onSelected={(color) => {
                      dispatch(
                        change(
                          outline2ColorNode.keyPath,
                          color ? color.id : null,
                        ),
                      )
                    }}
                    setAlert={setAlert}
                    isColorToneEnabled={isColorToneEnabled}
                  />
                )}
              </>
            )}
        </>
      )}
    </TileSet>
  )
}

export default TextStyleInput
