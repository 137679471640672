// TODO: remove this sentence when switched to `strict: true` in tsconfig // @ts-expect-error this is a js file
import { Select } from './nodes'
import { OrFn, AnyRow, AnyNodeOpts, Immutable } from './types'

type SelectOpts = AnyNodeOpts & {
  options: Immutable<OrFn<AnyRow[]>>
  visibleOptions?: Immutable<OrFn<AnyRow[]>>
  multiple?: boolean
}

const _Select: (opts: SelectOpts) => any = Select

export default _Select
